.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.75em 3.125em;
  @media screen and (max-width: 495px) {
    padding: 1.75em 2.125em;
  }
}

body {
  height: 100vh;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: AcuminPro;
  src: url("./assets/Acumin_Pro_Medium.otf");
}

@font-face {
  font-family: Poppins;
  src: url("./assets/Poppins-Light.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: AcuminPro;
  margin: 0;
}

p {
  line-height: 29px;
  margin: 0;
}

* {
  font-family: Poppins;
}
